@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Sedgwick+Ave+Display&display=swap');

body {
  position: relative;
  /*background: linear-gradient(45deg, rgb(246,46,36), rgb(234,88,12), rgb(255,133,27), rgb(253,224,71), rgb(2,103,145), rgb(29,35,70));*/
  background: linear-gradient(45deg, rgba(153, 27, 27, 1), rgba(220, 38, 38, 1), rgba(249, 115, 22, 1), rgba(250, 204, 21, 1), rgba(34, 197, 94, 1), rgba(59, 130, 246, 1), rgba(124, 58, 237, 1), rgba(91, 33, 182, 1));
  background-size: 250%;
  animation: gba 20s infinite;
  overflow: hidden;
  z-index: 1;
}

body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3));
  pointer-events: none;
  z-index: -1;
}

@keyframes gba {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  text-align: center;
  --tooltip-tail: 0.1875rem
    /* 3px */
  ;
  --tooltip-color: #ffffff;
  --tooltip-text-color: var(--fallback-nc, oklch(var(--nc)/1));
  --tooltip-tail-offset: calc(100% + 0.0625rem
      /* 1px */
      - var(--tooltip-tail));
}

.tooltip:before {
  max-width: 20rem
    /* 320px */
  ;
  border-radius: 0.25rem
    /* 4px */
  ;
  padding-left: 0.5rem
    /* 8px */
  ;
  padding-right: 0.5rem
    /* 8px */
  ;
  padding-top: 0.25rem
    /* 4px */
  ;
  padding-bottom: 0.25rem
    /* 4px */
  ;
  font-size: 0.875rem
    /* 14px */
  ;
  line-height: 1.25rem
    /* 20px */
  ;
  background-color: #fff;
  color: #000000;
  width: max-content;
}

.range {
  height: 1rem;
  cursor: pointer;
  appearance: none;
  --range-shdw: var(--fallback-bc, oklch(var(--bc)))/1;
  overflow: hidden;
  border-radius: var(--rounded-box, 1rem);
}

.scrollbarApp::-webkit-scrollbar {
  width: 2px;
}

.scrollbarApp::-webkit-scrollbar-thumb {
  background: #ea580c;
  border-radius: 25px;
}

.scrollbarApp::-webkit-scrollbar-thumb:hover {
  background: #f97316;
}

.scrollbarApp::-webkit-scrollbar-track {
  background: black;
  border-radius: 25px;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #ea580c;
  border-radius: 25px;
}

.scrollbar::-webkit-scrollbar-thumb:active {
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #f97316;
}

.scrollbar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 25px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
}

.scrollbar::-webkit-scrollbar-track:hover,
.scrollbar::-webkit-scrollbar-track:active {
  background: transparent;
}

.audio-recorder-visualizer {
  width: 100px;
}

.numbered-list {
  list-style-type: decimal;
}

ul {
  list-style-type: circle;
  margin-left: 1.5em;
}

ul ul {
  list-style-type: circle;
  margin-left: 1.5em;
}

ol {
  list-style-type: upper-roman;
  margin-left: 1.5em;
}

ol ol {
  list-style-type: decimal;
  margin-left: 1.5em;
}

ol ol ol {
  list-style-type: circle;
  margin-left: 1.5em;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in.active {
  opacity: 1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 5s linear infinite;
}

.pause {
  animation-play-state: paused;
}

.transition-ml {
  transition: margin-left 1s ease-in-out;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.leave {
  fill: #9ca3af;
}

.hovered {
  fill: #ff6347;
}

#player {
  background-color: #ffffff;
  border-radius: 8px;
}

#player .bmpui-ui-uicontainer {
  font-family: "Montserrat Variable", sans-serif;
}

.bmpui-ui-watermark,
.bmpui-ui-settingstogglebutton {
  display: none;
}